import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '../Helpers/User'

Vue.use(VueRouter)

function guardMyroute(to, from, next) {
  var isAuthenticated = false;

  if (User.loggedIn())
    isAuthenticated = true;
  else
    isAuthenticated = false;

  if (isAuthenticated) {
    next(); // allow to enter route
  }
  else {
    next('/login'); // go to '/login';
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard/Dashboard.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'Home',
      //   breadcrumb: [
      //     {
      //       text: 'Home',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    // { 
    //   path: '*', 
    //   component: ()=> import('@/views/Dashboard/Dashboard.vue')
    // },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Orders/Orders.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'All Orders',
      //   breadcrumb: [
      //     {
      //       text: 'orders',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/processing-orders',
      name: 'processing-orders',
      component: () => import('@/views/Orders/ProcessingOrders.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'Processing Orders',
      //   breadcrumb: [
      //     {
      //       text: 'processing orders',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/purchasing',
      name: 'purchasing',
      component: () => import('@/views/Purchase/Purchase.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'Purchasing',
      //   breadcrumb: [
      //     {
      //       text: 'purchasing',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/purchase-orders',
      name: 'purchase-orders',
      component: () => import('@/views/Purchase/PurchaseOrders.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'Purchase Orders',
      //   breadcrumb: [
      //     {
      //       text: 'puchase-orders',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Products/Products.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
      // meta: {
      //   pageTitle: 'Products',
      //   breadcrumb: [
      //     {
      //       text: 'Products',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/supplier-management',
      name: 'supplier-management',
      component: () => import('@/views/Settings/SupplierManagement/SupplierManagement.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },

    {
      path: '/accounts',
      name: 'accounts',
      component: () => import('@/views/Accounts/Accounts.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('@/views/UserManagement/UserManagement.vue'),
      beforeEnter: (to, from, next) => {
        if (!User.loggedIn()) {
          next({ name: 'login' })
        }

        // if (User.role() != 'admin') {
        //   next({ name: 'not-authorized' })
        // }
        else {
          next({})
        }
      }
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/Settings/roles/roles.vue'),
      beforeEnter: (to, from, next) => {
        if (!User.loggedIn()) {
          next({ name: 'login' })
        }
        else {
          next({})
        }
      }
    },
    {
      path: '/category',
      name: 'category',
      component: () => import('@/views/Settings/category/category.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },
    {
      path: '/role-permission',
      name: 'role-permission',
      component: () => import('@/views/Settings/rolepermission/rolepermission.vue'),
      beforeEnter: (to, from, next) => {
        if (!User.loggedIn()) {
          next({ name: 'login' })
        }
        else {
          next({})
        }
      }
    },
    {
      path: '/expenses-List',
      name: 'expenses-List',
      component: () => import('@/views/expense/expenseList.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },
    {
      path: '/incoming-invoice',
      name: 'incoming-invoice',
      component: () => import('@/views/expense/IncomingInvoice/IncomingInvoice.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },

    {
      path: '/profile-management',
      name: 'profile-management',
      component: () => import('@/views/ProfileManagement/ProfileManagement.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Dashboard/Dashboard.vue'),
      meta: { requiresAuth: true, adminAuth: true, managerAuth: false },
      beforeEnter: guardMyroute
    },
    {
      path: '/listing',
      name: 'listing',
      component: () => import('@/views/Listing/ListingDetails.vue'),
      beforeEnter: guardMyroute
    },

    {
      path: '/listings/:id',
      name: 'listings',
      component: () => import('@/views/Listing/ListingsDetails.vue'),
      beforeEnter: guardMyroute
    }
  ],
})

export default router
