import AppStorage from './AppStorage'

class User{
    
    responseAfterLogin(res){

        const token = res.data.token
        
        const username = res.data.username

        const userid = res.data.userid

        const userrole =  res.data.userrole

        const  useravatar = res.data.useravatar

        AppStorage.store(username,token,userid,userrole,useravatar)

    }

    hasToken(){
        const storedToken = AppStorage.getToken()

        if (storedToken){
            return true
        }
        else{
            return false
        }
    }

    loggedIn(){
        return this.hasToken()
    }

    logout(){
        AppStorage.clear()
        window.location ='/login'
    }

    name(){
        if(this.loggedIn()){
            return AppStorage.getUserName()
        }
    }

    id(){
        if(this.loggedIn()){
            return AppStorage.getUserId()
        }
    }

    token(){
        if(this.loggedIn()){
            return AppStorage.getToken()
        }
    }

    role(){
        if(this.loggedIn()){
            return AppStorage.getUserRole()
        }
    }

    avatar(){
        if(this.loggedIn()){
            return AppStorage.getUserAvatar()
        }
    }
    checkPermission(permission){
       let permissions =   localStorage.getItem('user_permisions')
       permissions = permissions ? JSON.parse(permissions) : []
       return  permissions.includes(permission)
    
    }

}

export default User = new User();